import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/virtual/pos', config);
}

const get = async (id) => {
    return FinanceApi.get('/virtual/pos/' + id);
}

const store = async (data) => {
    if (data.id) {
        return FinanceApi.put('/virtual/pos/' + data.id, data);
    }
    return FinanceApi.post('/virtual/pos', data);
}

export default {
    getAll,
    get,
    store
}
